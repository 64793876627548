import AuthDesignSection from './authDesignSection.svg'
import BackButtonIcon from './backButtonIcon.svg'
import GoogleIcon from './googleIcon.svg'
import EyeIcon from './eyeIcon.svg'
import EyeSlashIcon from './eyeSlashIcon.svg'
import LocationIcon from './locationIcon.svg'

import aeroPlane from './aeroPlane.svg'
import age from './age.svg'
import gender from './gender.svg'
import member from './member.svg'
import budget from './budget.svg'
import startDate from './startDate.svg'
import endDate from './endDate.svg'
import startLocation from './startLocation.svg'
import endLocation from './endLocation.svg'
import editButton from './editButton.svg'
import ChatButton from './ChatButton.svg'
import ProfileIcon from './ProfileIcon.svg'
import CalendarIcon from './CalenderIcon.svg'
import ChatNow from './chatNow.svg'
import deleteIcon from './deleteIcon.svg'
import editPic from './editPic.svg'
import ChatInputIcon from './chatInputIcon.svg'
import AddEmojiIcon from './addEmojiIcon.svg'
import Ninedots from './nineDots.svg'
import searchIcon from './searchIcon.svg'
import hyderabad from './hyderabad.svg'
import banglore from './banglore.svg'
import mumbai from './mumbai.svg'
import delhi from './delhi.svg'
import signup from './signup.svg'
import instagram from './footer/instagram.svg'
import facebook from './footer/facebook.svg'
import twitter from './footer/twitter.svg'
import youtube from './footer/youtube.svg'
export const SVG = {
  AuthDesignSection,
  BackButtonIcon,
  GoogleIcon,
  EyeIcon,
  EyeSlashIcon,
  aeroPlane,
  endLocation,
  startLocation,
  endDate,
  startDate,
  budget,
  member,
  age,
  gender,
  LocationIcon,
  editButton,
  ChatButton,
  ProfileIcon,
  CalendarIcon,
  ChatNow,
  deleteIcon,
  editPic,
  ChatInputIcon,
  AddEmojiIcon,
  Ninedots,
  searchIcon,
  hyderabad,
  banglore,
  mumbai,
  delhi,
  signup,
  instagram,
  facebook,
  twitter,
  youtube,
}
