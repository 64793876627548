import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  padding: 20px;
`

export const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const ImageWrapper = styled.div`
  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 3%;
  width: ${(props) => props.width || 'auto'};
  position: relative;
  border-radius: ${(props) => props.borderRadius || '0'};
  background-color: ${(props) => (props.backgroundColor ? `${props.backgroundColor}` : 'white')};
  aspect-ratio: 1;
`

export const Heading = styled.h1`
  font-size: 3vw;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 10px;
  color: white;
  @media (max-width: 786px) {
    font-size: 6vw;
  }
`
export const PublishHeading = styled.h1`
  font-size: 3vw;
  margin: 10px;
  color: white;
  @media (max-width: 786px) {
    font-size: 6vw;
  }
`
export const BottomHeading = styled.h1`
  font-size: 3vw;
  bottom: -40%;
  left: 0;
  margin: 10px;
  position: relative;
  width: 70%;
  @media (max-width: 786px) {
    font-size: 6vw;
  }
`

export const SubText = styled.p`
  width: 50%;
  font-size: 1.25vw;
  position: absolute;
  bottom: 15%;
  left: 10px;
  color: white;
  @media (max-width: 786px) {
    font-size: 2.5vw;
  }
`
export const LeftText = styled.p`
  font-size: 1.25vw;
  text-align: right;
  @media (max-width: 786px) {
    font-size: 2.5vw;
  }
`

export const RightSubText = styled.p`
  padding: 5% 5% 5% 0%;
  font-size: 1.25vw;
  font-weight: 800;
  width: 30%;
  right: 0;
  text-align: right;
  @media (max-width: 786px) {
    font-size: 2.5vw;
  }
`

export const SmallText = styled.span`
  font-family: Montserrat;
  font-weight: 700;
  text-align: left;

  font-size: 3vw;
  @media (max-width: 786px) {
    font-size: 6vw;
  }
`

export const BackgroundImage = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 15px;
  }
`
export const PublishButton = styled.button`
  height: 135px;
  font-size: 3vw;
  padding: 1%;
  cursor: pointer;
  background-color: #8dd3bb;
  width: ${(props) => props.width || 'auto'};
  border-radius: 10px;
  font-weight: 700;
  border: none;
  color: rgb(0, 0, 0);
  @media (max-width: 786px) {
    font-size: 6vw;
    height: auto;
    width: 100%;
  }
`
export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.direction === 'column' ? 'column' : 'row')};
  font-size: ${(props) => props.fontSize || 'min(1vw,32px)'};
  justify-content: space-between;
  align-items: ${(props) => props.alignItems || 'center'};
  gap: ${(props) => props.gap || '10px'};
  padding: ${(props) => props.padding || '0'};
  margin: ${(props) => props.margin || '0'};
  aspect-ratio: ${(props) => props.aspectRatio || 'auto'};
  background-image: ${(props) => (props.backgroundImage ? `url(${props.backgroundImage})` : 'none')};
  background-size: cover;
  background-position: center;
  border-radius: ${(props) => props.borderRadius || '0'};
  color: ${(props) => props.color || 'black'};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  position: relative;

  &.main {
    @media (max-width: 786px) {
      flex-direction: column-reverse;
    }
  }
`
export const PublishNowContainer = styled(FlexContainer)`
  @media (max-width: 786px) {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`
export const PublishNowContent = styled(FlexContainer)`
  @media (max-width: 786px) {
    padding: 5%;
    width: 100%;
    height: 100%;
  }
`
